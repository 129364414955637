import React, { useEffect, useState } from "react";
import MenuItem from "./MenuItem";
import { menuItemData } from './data';
import './menu.scss';
import MenuDuskSunset from "./MenuDuskSunset";
import { connect } from "react-redux";
import { setIndex } from "..";

const MenuContainer = ({ index, setIndex, url, onUrlChange, mounted }) => {
    const [streetSelected, setStreetSelected] = useState(0);

    useEffect(() => {
       console.log('HANDLE INDEX', index);
        setStreetSelected(index)

    }, [index])
    useEffect(() => {
        console.log('update levelsdata');
        const levelsData = menuItemData[streetSelected];
        onUrlChange(levelsData);

        setIndex(streetSelected);
    }, [streetSelected]);


    return (
        <>
        <ul className="menu-group">
            { menuItemData.map((item, index) => (
                <MenuItem selected={streetSelected === index} index={index} item={item} key={item.menuItemLabel} className="menu-item" setSelected={(e) => setStreetSelected(e)} url={url} />
            ))}

        </ul>
        </>
    )
}

const mapStateToProps = (state) => ({
    index: state.tour.index,
})

const mapDispatchToProps = (dispatch) => ({
    setIndex: (index) => dispatch(setIndex(index))

})

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);