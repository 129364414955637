import React, { useEffect, useRef, useState } from "react";
import { utilAnimate } from "../../util/utilAnimate";
import SideMenu from "./sideMenu/SideMenu";
import View3dAPP from "./ViewTour_H3SA";
import { connect } from "react-redux";

const ViewVirtualTour = () => {
    const containerRef = useRef(null);
    const [iFrame, setIframe] = useState({
        menuItemLabel: 'East',
        type: 'standalone',
        data: process.env.PUBLIC_URL + '/vr-app/east/index.htm',
      });
    useEffect(() => { 
        handleUrlChange({
            menuItemLabel: 'East',
            type: 'standalone',
            data: process.env.PUBLIC_URL + '/vr-app/east/index.htm',
          })
        utilAnimate.fadeIn(containerRef.current);
        
    }, []);

    

    const handleUrlChange = (url) => {
        
        setIframe(url);
    };

    return (
                <div ref={containerRef} className="tour--rotations">

                    <View3dAPP url={iFrame} />

                </div>
            
    )
}
const mapStateToProps = (state) => ({
})
const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewVirtualTour);